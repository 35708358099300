@import "../../../Services/default/styles/colors.scss";

.header {
  display: flex;
	align-items: center;
	justify-content: center;
	height: 63px;
	max-width: 1450px;
  margin: 0 auto;
  box-sizing: border-box;	
	position: sticky;
	top: 0;
	background: $mainBG;
	text-align: center;
	padding: 12px 0;
	border-bottom: 1px solid $headerBorder;
	margin-bottom: 15px;
	z-index: 9;
	& > button {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 8px;
	}
	.pageTitle {
		font-size: 1em;
		color: $primary;
	}
}

.subcategoryItems {
	padding: 0 15px;
	margin: 0 auto 20px;
	max-width: 1450px;
}
