@import '../../Services/default/styles/colors.scss';

.infoWrapper {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 50px;
  display: grid;
  place-items: center;

  .infoText {
    color: $primary;
    font-weight: 500;
    inset: 0;
    font-size: 1.25em;
    padding: 0 1rem;
    text-align: center;
  }
}
