@import '../../../Services/default/styles/colors.scss';
@mixin blueButton($pad) {
  background: $blueTitleText;
  border: none;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  padding: $pad 0;
  width: 100%;
  border-radius: 8px;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  height: 63px;
  max-width: 1450px;
  margin: 0 auto;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  background: #fff;
  text-align: center;
  padding: 12px 10px;
  border-bottom: 1px solid $headerBorder;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  .btns {
    display: flex;
    .share {
      border: none;
      background: none;
      margin-right: 16px;
    }
    .heart {
      border: none;
      background: #f6f6f6;
      border-radius: 50%;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        display: block;
      }
    }
  }
}

.main {
  padding-top: 8px;
  background: #fff;
  border-bottom: 1px solid $goodsBorder;
  max-width: 1450px;
  margin: 0 auto;

  .logoAndTitle {
    padding: 0 10px;
    display: flex;
    .logo {
      display: block;
      width: 33px;
      height: 33px;
      margin-right: 10px;
    }
    .title {
      font-size: 1em;
      letter-spacing: 0.2px;
      font-weight: 500;
    }
  }

  .sliderWrapper {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid $goodsBorder;
    position: relative;

    &.fullScreen {
      inset: 0;
      position: fixed;
      z-index: 999;
      width: 100%;
      height: 100vh;
      overflow: hidden;
      background: rgba(0, 0, 0, 0.96);
      display: flex;
      justify-content: center;
      align-items: center;
      animation: pop-up-swiper;
      animation-duration: 0.19s;
    }

    .imageWrapper {
      display: flex;
      justify-content: center;
    }

    .swiperImage {
      height: 250px;
      width: 100%;
      object-fit: contain;
    }

    .swiperImageFullScreen {
      max-width: calc(100vh - 250px);
      height: calc(100vh - 250px);

      @media screen and (max-width: 998px) {
        max-width: calc(100vh - 20px);
      }
    }

    @keyframes pop-up-swiper {
      from {
        opacity: 0;
        visibility: hidden;
        top: -100%;
      }
      to {
        opacity: 1;
        visibility: visible;
        top: 0;
      }
    }

    .discount {
      position: absolute;
      top: 16px;
      left: 10px;
      z-index: 2;
      background: #e13636;
      width: 42px;
      height: 20px;
      color: #fff;
      font-weight: 500;
      border-radius: 6px;
      font-size: 1em;
      text-align: center;
      line-height: 20px;
    }
  }

  .priceInfo {
    padding: 0 16px 16px;
    margin-bottom: 14px;
    border-bottom: 1px solid $goodsBorder;
    .sumPrice {
      font-size: 1.125em;
      font-weight: 600;
      margin-bottom: 12px;
      color: $primary;
    }
    .itemPriceAndCount {
      margin-bottom: 4px;
      color: $goodsTextColor;
      span {
        font-size: 0.95em;
        font-weight: 600;
        background: $subcategoryBG;
        padding: 3px 0.4em;
        border-radius: 4px;
      }
    }
  }

  .info {
    color: #8491b1;
    padding: 0 16px 16px;
    margin-bottom: 14px;

    &:not(:last-child) {
      border-bottom: 1px solid $goodsBorder;
    }
    p {
      font-size: 0.875em;
      letter-spacing: 0.2px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .nav {
    padding: 0 16px;
    margin-bottom: 16px;
    @media screen and (min-width: 998px) {
      display: grid;
      grid-template-columns: 450px 300px;
      justify-content: space-between;
    }
    .socialLinks {
      display: flex;
      justify-content: space-between;
      margin-bottom: 9px;
      .link {
        background-color: #fff;
        color: $blueTitleText;
        border: 2px solid $blueTitleText;
      }
    }
    .link {
      @include blueButton(0);
      height: 50px;
      img {
        display: block;
        margin-right: 8px;
      }
      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  }
}

.footer {
  background: $mainBG;
  padding: 13px 5px;
  margin: 0 auto;
  max-width: 1450px;
  .subtitle {
    color: $primary;
    font-size: 0.875em;
    font-weight: 400;
    margin-bottom: 6px;
    margin-left: 12px;
  }
  .subproductsWrapper {
    border-radius: 6px;
    overflow: hidden;
  }
}

.swiperBtn {
  box-sizing: border-box;
  background: none;
  border: 2px solid #0a2463;
  height: 10px;
  width: 10px;
  opacity: 1;
  transition: all 0.5s ease;

  &:global(.swiper-pagination-bullet-active) {
    width: 20px;
    border-radius: 5px;
    background-color: #0a2463;
    transition: all 0.5s ease;
    border: 2px solid #0a2463;
  }
}

.fullScreenSwiperInterface {
  width: 50%;
  position: absolute;
  z-index: 99;
  align-items: center;
  display: flex;
  justify-content: space-between;
  top: 14px;
  left: 16px;

  .closeBtn {
    background-color: #dceaf8;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin-right: 25px;
    border: none;

    .backImg {
      width: 25px;
      height: 25px;
      transform: rotate(180deg);
      filter: brightness(0) saturate(100%) invert(30%) sepia(70%) saturate(1617%) hue-rotate(192deg) brightness(97%) contrast(95%);
    }
  }

  .sliderData {
    color: #fff;
  }
}
