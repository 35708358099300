$primary: #0A2463;

/* text */

$primaryText: #102A43;
$secondaryText: #677D92;
$blueTitleText: #146FD1;
$goodsTextColor: #424F5E;
$blackText: #3B3C3C;

/* backgrounds */
$mainBG: #F6F6F6;
$whiteBG: #fff;
$subcategoryBG: #EBEFF5;
$primaryButton: #2D4379;
$grayBG: #f2f2f2;

/* borders */

$grayBorder: #E0DEDE;
$goodsBorder: #EBEDEF;
$headerBorder: #D6E3F4;