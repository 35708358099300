@import '../../../Services/default/styles/colors.scss';
%detailsButton {
  background: rgba($headerBorder, 0.38);
  width: 30px;
  height: 30px;
  color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

%circlePin {
  content: '';
  display: block;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  margin-right: 9px;
}

@mixin circlePinColor($color) {
  .categoryTitle span:first-child::before {
    @extend %circlePin;
    background-color: $color;
  }
  .subcategoryRoute {
    span:first-child {
      display: flex;
      align-items: center;
      flex: auto;
      &::before {
        @extend %circlePin;
        background-color: $color;
      }
    }
  }
}

.header {
  .title {
    text-align: center;
    font-weight: 500;
    font-size: 1em;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  height: 63px;
  border-bottom: 1px solid $headerBorder;
  margin-bottom: 10px;
}

.container {
  padding: 15px;
  max-width: 1450px;
  margin: 0 auto;

  .apartmentInfo {
    background: #fff;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 13px 14px 13px 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin-bottom: 15px;

    .imgWrapper {
      background: none;
      border: none;
      max-width: 140px;
      height: 140px;
      margin-right: 14px;
      align-self: center;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }
    }
    .infoWrapper {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-self: flex-end;
      gap: 0.3rem;

      .info {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        width: 135px;
        gap: 5px;
        .flexWrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
        }
        .infoCell {
          display: flex;
          padding: 3px 6px;
          font-size: 0.875em;
          color: $blackText;
          background: rgba($headerBorder, 0.38);
          border-radius: 5px;
          font-weight: 500;
          height: 17px;
          .smallerText {
            font-size: 0.75em;
          }
        }
      }
    }

    .budgetTotal {
      color: $blackText;
      display: flex;
      flex-direction: column;
      font-size: 0.9375em;
      text-align: right;
      strong {
        font-size: 1.25em;
      }
    }
  }

  .category {
    margin-bottom: 13px;
    display: block;

    &[open] {
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.08),
        0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 0 0 10px 10px;
    }

    &:first-child {
      @include circlePinColor(rgba($primary, 0.52));
    }

    &:nth-child(2) {
      @include circlePinColor(#fcb940);
    }

    &:nth-child(3) {
      @include circlePinColor(#41b954);
    }

    &:nth-child(4) {
      @include circlePinColor(#9340fc);
    }

    &[open] > .categoryTitle {
      border-radius: 10px 10px 0 0;
      box-shadow: none;
    }
    &[open] > .categoryTitle::after {
      content: url('../../../Assets/category-arrow-down.svg');
      display: block;
      @extend %detailsButton;
    }

    .categoryTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background: $whiteBG;
      box-sizing: border-box;
      padding: 15px;
      color: $blackText;
      font-size: 1em;
      font-weight: 500;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.08),
        0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      list-style: none;

      &::-webkit-details-marker {
        display: none;
      }

      span {
        display: block;
        &:first-child {
          display: flex;
          align-items: center;
          margin-bottom: 4px;
        }

        &:last-child {
          font-size: 1.05em;
          margin-left: 21px;
        }
      }

      &::after {
        content: url('../../../Assets/category-arrow-left.svg');
        display: block;
        @extend %detailsButton;
      }
    }
    .subcategoryRoute {
      background: #eff4fb;
      border-bottom: 1px solid $goodsBorder;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $primaryText;
      font-size: 1em;
      font-weight: 500;
      padding: 15px 7px 15px 15px;
      text-decoration: none;

      &:last-child {
        border-radius: 0 0 10px 10px;
        border-bottom: none;
        margin-bottom: 10px;
      }

      &::after {
        flex: 0;
        content: url('../../../Assets/subcategory-arrow-left.svg');
        display: block;
        width: 26px;
        height: 26px;
      }

      span:last-child {
        margin-right: 17px;
      }
    }
  }
}

.imgContainer {
  display: grid;
  place-items: center;
  height: 100%;
  .modalPhoto {
    display: block;
    max-width: calc(100vw - 50px);
    max-height: calc(100vh - 250px);
    width: 100%;
    height: 100%;
  }
}
