@import '../../../Services/default/styles/colors.scss';
.modal {
  position: fixed;
  inset: 0;
  background-color: $mainBG;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  overflow: hidden;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  right: -100%;

  .modalHeader {
    max-width: 1450px;
    margin: 0 auto;
    border-bottom: 1px solid $headerBorder;
    position: relative;
    text-align: center;
    height: 63px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .modalClose {
      cursor: pointer;
      position: absolute;
      background: none;
      border: none;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      padding: 14px 14px 14px 8px;
      display: block;
      .backImg {
        display: block;
        transform: rotateZ(180deg);
      }
    }
    .modalHeaderTitle {
      font-size: 1em;
      font-weight: 500;
      color: $primary;
      line-height: 46px;
    }
  }

  .modalContent {
    overflow: auto;
    margin: 0 auto;
    max-width: 1450px;
    padding: 20px 15px;
    width: 100vw;
    height: calc(100% - 63px);
    box-sizing: border-box;
  }
}
.modalEnterDone {
  opacity: 1;
  pointer-events: auto;
  right: 0;
}

.modalExit {
  opacity: 0;
}
