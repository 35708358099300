.back {
  background: none;
  border: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 8px;

  .backImg {
    display: block;
    transform: rotateZ(180deg);
  }

  .link {
    padding: 14px 14px 14px 8px;
    display: block;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}
