@import '../../Services/default/styles/colors.scss';

.blueLink {
  all: unset;
  background-color: $primary;
  color: #fff;
  display: grid;
  cursor: pointer;
  place-items: center;
  padding: 0.2rem 0.4rem;
  border-radius: 6px;
}
