@import "./colors.scss";


@font-face {
  font-family: "ProximaNova";
  src: url(../fonts/ProximaNova-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: "ProximaNova";
  src: url(../fonts/ProximaNova-Semibold.ttf);
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "ProximaNova";
  src: url(../fonts/ProximaNova-Bold.ttf);
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "ProximaNova";
  src: url(../fonts/ProximaNova-Extrabold.ttf);
  font-weight: 700;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  font-family: "ProximaNova", sans-serif;
}

body {
  font-size: 16px;
  background: $mainBG;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #2a85ff;
}
::-moz-scrollbar {
  width: 5px;
}
::-moz-scrollbar-thumb {
  background-color: #2a85ff;
}
::-o-scrollbar {
  width: 5px;
}
::-o-scrollbar-thumb {
  background-color: #2a85ff;
}
::-ms-scrollbar {
  width: 5px;
}
::-ms-scrollbar-thumb {
  background-color: #2a85ff;
}
