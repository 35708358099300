@import '../../../Services/default/styles/colors.scss';

.item {
  background: $whiteBG;
  border-bottom: 1px solid $goodsBorder;
  padding: 16px;
  &:first-child {
    border-radius: 5px 5px 0 0;
  }
  &:last-child {
    border: none;
    border-radius: 0 0 5px 5px;
  }

  &:only-child {
    border-radius: 5px;
  }

  .itemLink {
    display: block;

    text-decoration: none;

    .itemInfo {
      display: flex;
      color: $goodsTextColor;

      .itemImgContainer {
        width: 90px;
        min-height: 100%;
        border-radius: 5px;
        margin-right: 13px;
        position: relative;

        &::after {
          position: absolute;
          top: 2px;
          left: 2px;
          z-index: 2;
          content: attr(discount-attribute);
          background: #e13636;
          width: 30px;
          height: 15px;
          color: #fff;
          font-weight: 500;
          border-radius: 6px;
          font-size: 0.75em;
          text-align: center;
          line-height: 16px;
        }
        img {
          border-radius: 5px;
          display: block;
          width: 90px;
          height: 90px;
          object-fit: cover;
        }
      }

      .itemTitle {
        font-size: 0.875em;
        font-weight: 500;
        height: 34px;
        max-height: 34px;
        margin-bottom: 8px;
        overflow: hidden;
      }

      .itemPriceAndCount {
        margin-bottom: 4px;
        span {
          font-size: 0.75em;
          font-weight: 600;
          background: $subcategoryBG;
          padding: 3px 0.4em;
          border-radius: 4px;
        }
      }

      .itemSum {
        font-weight: 600;
        font-size: 1.125em;
      }
    }
  }
  .openButton {
    cursor: pointer;
    position: relative;
    border: none;
    border-radius: 6px;
    color: $primary;
    font-size: 0.8125em;
    background: $subcategoryBG;
    width: 100%;
    max-width: 400px;
    padding: 5px 8px 5px 14px;
    margin-top: 8px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
