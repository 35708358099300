@import "../../../Services/default/styles/colors.scss";

.room {
	background: #fff;
	display: block;
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.08);
	padding: 0 5px;
	border-bottom: 1px solid $grayBG;
	&[open] {
		padding: 0 5px 10px;
	}
	&[open] > .roomTitle::after {
		content: url("../../../Assets/subcategory-arrow-down.svg");
		display: block;
		width: 26px;
		height: 26px;
	}

	&:first-child {
		border-radius: 10px 10px 0 0;
	}

	&:last-child {
		border-radius: 0 0 10px 10px;
		border-bottom: none;
		margin-bottom: 30px;
	}
	&:only-child {
		border-radius: 10px;
	}
	.roomTitle {
		position: sticky;
		top: 56px;
		display: flex;
		background: #fff;
		justify-content: space-between;
		align-items: center;
		color: $primaryText;
		font-size: 1em;
		font-weight: 500;
		padding: 15px 7px 15px 15px;
		z-index: 3;
		list-style: none;
		border-radius: 5px;

		&::-webkit-details-marker {
			display: none;
		}
		span:first-child {
			display: flex;
			align-items: center;
			flex: auto;
			&:before {
				content: "";
				display: block;
				height: 11px;
				width: 11px;
				border-radius: 50%;
				background-color: #41b954;
				margin-right: 9px;
			}
		}
		span:last-child {
			margin: 0 17px;
			min-width: 24px;
		}
		&::after {
			flex: 0;
			content: url("../../../Assets/subcategory-arrow-left.svg");
			display: block;
			width: 26px;
			height: 26px;
		}
	}
}
